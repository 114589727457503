<template>
  <div>
    <rectangle-element
      v-if="layout.body.visible"
      :layout="layout.body"
      :skeleton="layout.skeleton"
    >
    </rectangle-element>
    <rating-element
      v-if="layout.ratings.visible"
      :rating="content.rating"
      :layout="layout.ratings"
      :skeleton="layout.skeleton"
    ></rating-element>
    <text-element
      v-if="layout.author.visible"
      :text="author"
      :layout="layout.author"
      :skeleton="layout.skeleton"
    ></text-element>
    <text-element
      v-if="layout.text.visible"
      :text="content.reviewText"
      :layout="layout.text"
      :skeleton="layout.skeleton"
    ></text-element>
    <icon-element
      v-if="layout.textIcon.visible"
      :icon="['fas', 'quote-left']"
      :layout="layout.textIcon"
      :skeleton="layout.skeleton"
    ></icon-element>
    <rectangle-element
      v-if="layout.footer.visible"
      :layout="layout.footer"
      :skeleton="layout.skeleton"
    ></rectangle-element>
    <text-element
      v-if="layout.venue.visible"
      :text="content.pageName"
      :layout="layout.venue"
      :skeleton="layout.skeleton"
    ></text-element>
    <text-element
      v-if="layout.totalReviews.visible"
      :text="totalReviews"
      :layout="layout.totalReviews"
      :skeleton="layout.skeleton"
    ></text-element>
    <rating-element
      v-if="layout.totalRatings.visible"
      :rating="content.pageRating"
      :layout="layout.totalRatings"
      :skeleton="layout.skeleton"
    ></rating-element>
    <text-element
      v-if="layout.cta.visible"
      text="Lasciaci una recensione"
      :layout="layout.cta"
      :skeleton="layout.skeleton"
    ></text-element>
    <icon-element
      v-if="layout.icon.visible"
      :icon="['fab', 'google']"
      :layout="layout.icon"
      :skeleton="layout.skeleton"
    ></icon-element>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

import isNull from "lodash/isNull";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "GoogleReviewElement",
  mixins: [layoutMixin],
  components: {
    RatingElement: () => import("@/components/core/RatingElement"),
    TextElement: () => import("@/components/core/TextElement"),
    RectangleElement: () => import("@/components/core/RectangleElement"),
    IconElement: () => import("@/components/core/IconElement"),
  },
  props: {
    content: Object,
    settings: Object,
  },
  data() {
    return {
      num: 0,
    };
  },
  mounted() {
    //
  },
  destroyed() {
    //
  },
  computed: {
    bodyLayout: function() {
      if (
        this.content &&
        this.content.pageCoverAsBackground &&
        !isNull(this.content.pageCover)
      ) {
        var l = cloneDeep(this.layout.body);
        l.background.type = 2;
        l.background.param = this.content.pageCover;
        return l;
      }
      return this.layout.body;
    },
    totalReviews: function() {
      return ` da ${this.content.pageRatingCount} recensioni`;
    },
    author: function() {
      if (this.content.reviewerName) {
        return ` da ${this.content.reviewerName}`;
      }
      return "";
    },
  },
  methods: {},
};
</script>
<style scoped></style>
